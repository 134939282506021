export const userType = {
  id: "",
  fullName: "",
  role: "",
  email: "",
  mobileNo: "",
  dialCode: "",
  aboutUs: "",
  address: "",
  image: "",
  logo: "",
  merchants_transaction_rate: "",
};
export const AssessmentsType = {
  id: "",
  name: "",
  passingScrore: 0,
  weightagePerQuestion: 0,
  skillType: "",
};
export const Category = {
  id: "",
  name: "",
  catType: "",
  subParentCategory: "",
  description: "",
  image: "",
  order: "",
  parentCategory: "",
  status: "active",
  icon: "",
  banner: "",
  altImageName: "",
  altIconName: "",
  bannerOverlayHeading: "",
  bannerOverlayBody: "",
  description: "",
  featured: "No",
  urlKey: "",
  metaTitle: "",
  metaDescription: "",
  keywords: "",
};
export const CategoryType = {
  id: "",
  name: "",
  cat_type: "",
  image: "",
  status: "active",
};
export const roleType = {
  id: "",
  name: "",
  loginPortal: "",
  status: "active",
  permissions: [
    {
      //  Done
      readDashboard: false,
      readContent: false,
      addContent: false,
      deleteContent: false,
      editContent: false,
      readAssessment: false,
      addAssessment: false,
      editAssessment: false,
      deleteAssessment: false,
      addQuestion: false,
      editQuestion: false,
      readQuestion: false,
      deleteQuestion: false,
      readRole: false,
      addRole: false,
      editRole: false,
      deleteRole: false,
      readSkillType: false,
      addSkillType: false,
      editSkillType: false,
      deleteSkillType: false,
      readAdmins: false,
      addAdmins: false,
      editAdmins: false,
      deleteAdmins: false,
      readTypes: false,
      addTypes: false,
      editTypes: false,
      deleteTypes: false,
      readSkills: false,
      addSkills: false,
      editSkills: false,
      deleteSkills: false,
    },
  ],
};
export const resellerCategoryType = {
  id: "",
  name: "",
  catType: "Reseller",
  description: "",
  image: "",
};
export const planType = {
  id: "",
  name: "",
  price: "",
  status: "active",
  interval: "Monthly",
  category: "",
  recommended: "",
  allowedProducts: "",
  feature: [],
};
export const continentType = { id: "", name: "", status: "active" };
export const featureType = {
  id: "",
  name: "",
  description: "",
  status: "active",
};
export const emailType = { id: "", subject: "", content: "", status: "active" };
export const cityType = {
  id: "",
  name: "",
  status: "active",
  countryId: "",
  regionId: "",
  continent: "",
};
export const bookingSystemType = {
  id: "",
  name: "",
  apiKey: "",
  secretKey: "",
};
export const holidaysType = {
  id: "",
  discOrPre: "",
  name: "",
  type: "",
  country: "",
  counties: "",
  amountOrPercent: "",
  number: "",
  applyFor: [],
  preOrPost: "",
  preDays: "",
  postDays: "",
  changesApply: "",
  changesDate: "",
  changesDateTo: "",
};
export const earlybirdpricingType = {
  id: "",
  name: "",
  discOrPre: "",
  startDate: "",
  country: "",
  counties: "",
  applyEarlyBirdPricing: [],
  endDate: "",
  inventory: [],
  lastMinutePricingFromDate: "",
  lastMinutePricingToDate: "",
  applyPriceType: "",
  changesDate: "",
  changesDateTo: "",
  notApply: "",
  notApplyCondition: "",
  notApplicableFor: [],
  blackOutDates: [],
  amountOrPercent: "",
  number: "",
  applyToDaysTimeSlot: "",
  daysToApply: [],
  timeSlots: [],
};
export const posType = { id: "", name: "", apiKey: "", url: "" };
export const crmType = { id: "", name: "", image: "", content: "" };
export const resellerType = {
  id: "",
  name: "",
  logo: "",
  category: "",
  contractDate: "",
  booking: "",
  pax: "",
  country: "",
  contactPerson: "",
  contactPhone: "",
  contactEmail: "",
};
export const couponType = {
  id: "",
  title: "",
  status: "active",
  description: "",
  couponCode: "",
  usesPerCoupon: "",
  usesPerCustomer: "",
  dateFrom: "",
  dateTo: "",
  discountType: "",
  discountAmount: "",
};
