import React, { useEffect, useState } from "react";
import ImageUpload from "../../../components/common/ImageUpload";
import GooglePlaceAutoComplete from "../../../components/common/GooglePlaceAutoComplete";
import Layout from "../../../components/global/layout";
import rolesModel from "../../../models/roles.model";
import SelectDropdown from "../../../components/common/SelectDropdown";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import Pagination from "react-js-pagination";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import environment from "../../../environment";
import { useDispatch } from "react-redux";
import { search_success } from "../../../redux/actions/search";
const Html = ({
  role,
  form,
  handleSubmit,
  setform,
  roles,
  addressResult,
  submitted,
  images,
  imageResult,
  getError,
  setEyes,
  eyes,
  back,
  emailCheck,
  emailErr,
  emailLoader,
  detail,
  ChangeStatus,
  statusChange,
  setcustomer,
  filter1,
  customerr,
  getCustomerData,
  Customer,
  loaging,
  total,
  pageChange,
  searchChange,
  clear,
  search,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isAllow = (key = "") => {
    let permissions = user.role?.permissions;
    let value = permissions?.[key];
    if (user.role.id == environment.adminRoleId) value = true;
    return value;
  };

  useEffect(
    (customerr) => {
      if (customerr == true) getCustomerData();
      else {
        return;
      }
    },
    [customerr]
  );

  const handlepage = (e) => {
    if (e == "Customer") {
      setcustomer(true);
      getCustomerData();
    } else {
      setcustomer(false);
    }
  };

  const searchHandle = (e) => {
    e.preventDefault();
    dispatch(search_success(search));
  };

  return (
    <>
      <Layout>
        {/* {customerr&& <form className="headerSearch ml-3" onSubmit={searchHandle}>
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => searchChange(e.target.value)}
            className="Searchbar"
          ></input>
          <i
            className="fa fa-search"
            onClick={searchHandle}
            aria-hidden="true"
          ></i>
          {search ? (
            <i
              className="fa fa-times remove-icon"
              onClick={clear}
              aria-hidden="true"
            ></i>
          ) : (
            <></>
          )}
        </form>} */}

        <div className="d-flex justify-content-between">
          {customerr && (
            <form
              className="headerSearch ml-3 useSearchbar"
              onSubmit={searchHandle}
            >
              <input
                type="text"
                placeholder="Search..."
                value={search}
                onChange={(e) => searchChange(e.target.value)}
                className="Searchbar"
              ></input>
              <i
                className="fa fa-search"
                onClick={searchHandle}
                aria-hidden="true"
              ></i>
              {search ? (
                <i
                  className="fa fa-times remove-icon"
                  onClick={clear}
                  aria-hidden="true"
                ></i>
              ) : (
                <></>
              )}
            </form>
          )}
        </div>
        {!customerr ? (
          <form onSubmit={handleSubmit}>
            <div className="pprofile1">
              <h3 className="ViewUser">
                {form && form.id ? "Edit" : "Add"}{" "}
                {form.role == "6540cfa35a7513892be61415" ? "Merchant" : "User"}
              </h3>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label>
                    First Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={form.firstName}
                    onChange={(e) =>
                      setform({ ...form, firstName: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label>
                    Last Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={form.lastName}
                    onChange={(e) =>
                      setform({ ...form, lastName: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="col-md-6 mb-3 useDropdown">
                  <label>
                    Role<span className="text-danger">*</span>
                  </label>
                  <SelectDropdown
                    id="statusDropdown"
                    displayValue="name"
                    placeholder="Select Role"
                    intialValue={form.role}
                    disabled={form.id ? true : false}
                    result={(e) => {
                      setform({ ...form, role: e.value });
                    }}
                    options={roles}
                    required
                  />
                  {submitted && !form.role ? (
                    <div className="invalid-feedback d-block">
                      Role is Required
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {role ? (
                  <></>
                ) : (
                  <div className="col-md-6 mb-3">
                    <label>Address</label>
                    <GooglePlaceAutoComplete
                      value={form.address}
                      result={addressResult}
                      id="address"
                      placeholder=""
                    />
                  </div>
                )}

                <div className="col-md-6 mb-3 ">
                  <label className=" d-block">
                    Mobile No<span className="star">*</span>
                  </label>

                  <div className="w-100  d-inline-flex">
                    <PhoneInput
                      value={form.dialCode + "" + form.mobileNo}
                      countryCodeEditable={false}
                      enableSearch={true}
                      placeholder=""
                      country="us"
                      onChange={(phone, country) => {
                        let phonenumber = phone.replace(country.dialCode, "");
                        setform({
                          ...form,
                          dialCode: country.dialCode,
                          mobileNo: phonenumber,
                        });
                      }}
                      required
                    />
                  </div>
                  {submitted && !form.dialCode ? (
                    <div className="invalid-feedback d-block">
                      DialCode is Required
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label>
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={form.email}
                    onChange={(e) => {
                      setform({ ...form, email: e.target.value });
                      emailCheck(e.target.value);
                    }}
                    required
                  />
                  {emailErr ? (
                    <div className="invalid-feedback d-block">{emailErr}</div>
                  ) : (
                    <></>
                  )}
                </div>
                {form?.role == "6540cfa35a7513892be61415" ? (
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Transaction Rate (%)</label>
                    <input
                      type="number"
                      className="form-control"
                      name="support_email"
                      value={form.transaction_rate}
                      onChange={(e) => {
                        setform({ ...form, transaction_rate: e.target.value });
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
                {form?.role == "6540cfa35a7513892be61415" ? (
                  <div className="col-md-6 mb-3">
                    <label>Business Name </label>
                    <input
                      type="text"
                      className="form-control"
                      value={form.companyName}
                      onChange={(e) => {
                        setform({ ...form, companyName: e.target.value });
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}


                {detail &&
                  detail.role &&
                  detail.role._id == "6540cfa35a7513892be61415" ? (
                  <div className="col-md-6 mb-3">
                    <label>Merchant Fee (%)</label>
                    <input
                      className="form-control"
                      type="number"
                      // onKeyPress={e=>methodModel.isNumber(e)}
                      onWheelCapture={e => {
                        e.target.blur()
                      }}
                      value={form.merchant_fee}
                      onChange={(e) => {
                        setform({ ...form, merchant_fee: e.target.value });
                      }}
                    />
                  </div>
                ) : null}

                <div className="col-md-6  mb-3">
                  <label className="lablefontcls">Image</label>
                  <br></br>
                  <ImageUpload
                    model="users"
                    result={(e) => imageResult(e, "image")}
                    value={images.image || form.image}
                    multiple={false}
                  />
                </div>
              </div>

              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-secondary discard mr-2"
                  onClick={(e) => back()}
                >
                  Back
                </button>
                {!form.id || (form.id && isAllow("editAdmins")) ? (
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                ) : null}
              </div>
            </div>
          </form>
        ) : (
          <div>
            <h3 className="hedding my-2">Merchant's Customers</h3>
            <div className="table-responsive">
              <div className="table_section ">
                <table class="table table-striped table-hover table-bordered">
                  <thead className="table_head">
                    <tr className="heading_row">
                      <th scope="col" className="table_data">
                        S.NO
                      </th>

                      <th scope="col" className="table_data">
                        Email
                      </th>
                      <th scope="col" className="table_data">
                        Name
                      </th>

                      <th scope="col" className="table_data">
                        mobileNo
                      </th>

                      <th scope="col" className="table_data">
                        Date
                      </th>
                    </tr>
                  </thead>
                  {!loaging && (
                    <tbody>
                      {Customer.map((data, index) => (
                        <tr className="data_row">
                          <td className="table_dats">{index + 1}</td>
                          <td className="table_datsd">{data?.email}</td>
                          {/* <td className="table_dats" onClick={()=>history(`/transactionDeatil/${data?.id}`)}>{data?.merchant_name || '--'}</td> */}
                          <td className="table_dats">{data?.fullName}</td>
                          <td className="table_dats">
                            {data?.mobileNo ? data?.mobileNo : "--"}
                          </td>
                          <td className="table_dats">
                            {moment(data?.createdAt).format("DD-MM-YY")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        )}

        {customerr && !loaging && total > filter1.count ? (
          <div className="paginationWrapper  pagination_end">
            <span>
              Show {filter1.count} from {total} Users
            </span>
            <Pagination
              activePage={filter1.page}
              itemClassPrev="back_page"
              itemClassNext="next_page"
              itemsCountPerPage={filter1.count}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={pageChange}
            />
          </div>
        ) : (
          <></>
        )}
      </Layout>
    </>
  );
};

export default Html;
