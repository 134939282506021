// src/components/TransactionTable.js

import React from 'react';
import  transactions from './trasactionlist' // Assuming you have the transactions data
import Layout from '../../../components/global/layout';

const TransactionTable = () => {
    const Params=new URLSearchParams(window.location.search);
    const TransactionFee=Params.get('transfee')
  return (
    <Layout>
        <h4 className='text-center'>Transactions Data</h4>
    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Date</th>
          <th>Description</th>
          {TransactionFee?<th>Transaction Fee</th>:null}
          <th>Amount</th>
          <th>Processing Fee</th>
          <th>Category</th>
          <th>Account</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        {transactions.map(transaction => (
          <tr key={transaction.id}>
            <td>{transaction.id}</td>
            <td>{transaction.date}</td>
            <td>{transaction.description}</td>
            {TransactionFee?<td>{transaction.transfee}</td>:null}
            <td>{transaction.amount}</td>
            <td>{transaction.processfee}</td>
            <td>{transaction.category}</td>
            <td>{transaction.account}</td>
            <td>{transaction.type}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </Layout>
  );
};

export default TransactionTable;
