import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastsStore } from "react-toasts";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { Editor } from '@tinymce/tinymce-react';
import { toast } from 'react-toastify';

const AddEditContent = ({ form, setform, modalClosed, Update }) => {
    const user = useSelector(state => state.user)
    const [languages, setLanguages] = useState([])
    const [Categories, setCategories] = useState([]);

 
    const GetCategory = () => {
        ApiClient.get(`categories/listing?status=active&type=main&catType=page`).then(res => {
            if (res.success) {
                setCategories(res.data);
            }
        })
    }
    useEffect(() => {
        GetCategory();
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (form.keyword && form.keyword.length == 0) { toast.error("Keyword is required"); return false }
        let value = {
            id: form.id,
            title: form.title,
            slug: form.slug,
            description: form.tiny_description ? form.tiny_description : form.description,
            meta_title: form.meta_title,
            meta_description: form.meta_description,
            keyword: form.keyword,
            category: form.category,
            subCategory: form.subCategory
        }
        if (!form?.subCategory) delete value?.subCategory
        let method = 'post'
        let url = 'cms'
        if (value.id) {
            method = 'put'
            url = 'cms/update'
        } else {
            delete value.id
        }
        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                toast.success(res.message)
                document.getElementById('closecontantModal').click()
                modalClosed()
            }
            loader(false)
        })
    }

    useEffect(() => {
        if (user && user.loggedIn) {

        }
    }, [])
    const [SubCategories, setSubCategories] = useState([]);
    const GetSubCategory = (e) => {
        ApiClient.get(`categories/listing?parentCategory=${e}&type=child`).then(res => {
            if (res.success) {
                setSubCategories(res.data);
            }
        })
    }

    const HandleCategoryChange = (e) => {
        const { value } = e.target;
        setform({ ...form, category: value });
        GetSubCategory(value);

    }

    useEffect(() => {
        GetSubCategory(form.category)
    }, [form.category])



    const HandleAddKeyword = () => {
        const KeywordData1 = form.keyword;
        KeywordData1.push(KeywordData);
        setform({ ...form, keyword: KeywordData1 });
        setKeywordData("")
    }

    const HandleDeleteKeyword = (value) => {
        const data = form.keyword;
        setform({ ...form, keyword: data.filter((item) => item != value) })

    }

    const [KeywordData, setKeywordData] = useState("");

    useEffect(() => {
        if (KeywordData.includes(",")) {
            setKeywordData("")
        }
    }, [KeywordData])
    return <>
        <a id="openContentModal" data-toggle="modal" data-target="#contentModal"></a>
        <div className="modal fade" id="contentModal" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{form && form.id ? 'Edit' : 'Add'} Content</h5>
                        <button type="button" id="closecontantModal" className="close" data-dismiss="modal" aria-label="Close" title='Close'>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body total_modal">
                            <div className="form-row roleForm">
                                <div className="col-md-12 mb-3">
                                    <label>Title <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control " value={form.title ? form.title : ''} onChange={e => setform({ ...form, title: e.target.value })} required />
                                </div>

                            <div className="col-md-12 mb-3">
                                   <div className='total_description'>
                                   <label>Description <span className="text-danger">*</span></label>
                                  {form.description=="" ?
                                  <Editor apiKey='e9b46x5ebse3zswyqxc5gpl8b5zzduu2ziq9r75c2s91ytpe' textareaName='content'
                                  initialValue={''}
                                   className='tuncketcls  description_bx'
                                     onEditorChange={(newValue, editor) => {
                                         setform({ ...form, tiny_description: newValue })
                                     }}
                                     required
                                 />
                                  :<Editor apiKey='e9b46x5ebse3zswyqxc5gpl8b5zzduu2ziq9r75c2s91ytpe' textareaName='content'
                                     initialValue={form?.description||''}
                                      className='tuncketcls'
                                        onEditorChange={(newValue, editor) => {
                                            setform({ ...form, tiny_description: newValue })
                                        }}
                                        required
                                    />}
                                   </div>
                                    
                                </div>

                                {!methodModel.isTranslatePage() ? <>
                                    <div className="col-md-6 mb-3" onChange={e => HandleCategoryChange(e)}>
                                        <label>Category <span className="text-danger">*</span></label>
                                        <select className='form-control' value={form.category} onChange={e => setform({ ...form, category: e.target.value })}>
                                            <option value="">Select Category</option>
                                            {Categories.map((item, index) => (<option value={item.id}>{item.name}</option>))}
                                        </select>
                                    </div>
                                    <div className="col-md-6  mb-3">
                                        <label>Sub Category </label>
                                        <select className='form-control' value={form.subCategory} onChange={e => setform({ ...form, subCategory: e.target.value })} >
                                            <option>Sub Category</option>
                                            {SubCategories.map((item, index) => (<option value={item.id}>{item.name}</option>))}
                                        </select>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label>Meta Title <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" value={form.meta_title ? form.meta_title : ''} onChange={e => setform({ ...form, meta_title: e.target.value })} required />

                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label>Meta Description <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" value={form.meta_description ? form.meta_description : ''} onChange={e => setform({ ...form, meta_description: e.target.value })} required />
                                    </div>

                                    <div className="col-md-12 mb-3 ">
                                      <div className='row flex-wrap justify-content-between'>
                                      <div className=' col-md-10'>
                                      <label>Meta Keyword <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" value={KeywordData} onKeyPress={(event) => event.key === "," ? HandleAddKeyword() : null} onChange={e => { e.key !== "," ? setKeywordData(e.target.value) : null }} />
                                      </div>
                                      <div className=' col-md-2 position-relative'>
                                      {form.keyword && form.keyword.map((item) => (<span className='border_absolute'>{item}<i className='fa fa-trash ml-1' onClick={e => HandleDeleteKeyword(item)}></i></span>))}
                                      </div>

                                      </div>
                                        
                                       
                                    </div>
                                </> : <></>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary discard m-0 mr-3" data-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary m-0 ">{form && form.id ? 'Update' : 'Create'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}

export default AddEditContent