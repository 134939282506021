import React from "react";
import Layout from "../../components/global/layout";

export default function TransactionVolume() {
  return (
    <Layout>
      <div>
        <h1 className="hedding mb-5">Manage Content</h1>
        <div>
          <div class="d-flex align-items-start">
            <div
              class="nav flex-column nav-pills me-3 setting-inner"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                class="nav-link active"
                id="v-pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-home"
                type="button"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
              >
                Chargeback Rate
              </button>
              <button
                class="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-profile"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
              >
                Settlement Time
              </button>
              <button
                class="nav-link"
                id="v-pills-messages-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-messages"
                type="button"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
              >
                Processing time
              </button>
              <button
                class="nav-link"
                id="v-pills-settings-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-settings"
                type="button"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
              >
                Payment Gateway Uptime
              </button>

              <button
                class="nav-link"
                id="fraud-tab"
                data-bs-toggle="pill"
                data-bs-target="#fraud"
                type="button"
                role="tab"
                aria-controls="fraud"
                aria-selected="false"
              >
                Fraud Detection
              </button>

              <button
                class="nav-link"
                id="customer-support-tab"
                data-bs-toggle="pill"
                data-bs-target="#customer-support"
                type="button"
                role="tab"
                aria-controls="customer-support"
                aria-selected="false"
              >
                Customer Support Metrics
              </button>

              <button
                class="nav-link"
                id="compliance-tab"
                data-bs-toggle="pill"
                data-bs-target="#compliance"
                type="button"
                role="tab"
                aria-controls="compliance"
                aria-selected="false"
              >
                Compliance Metrics
              </button>

              <button
                class="nav-link"
                id="refund-tab"
                data-bs-toggle="pill"
                data-bs-target="#refund"
                type="button"
                role="tab"
                aria-controls="refund"
                aria-selected="false"
              >
                Refund Rate
              </button>

              <button
                class="nav-link"
                id="reconcilation-tab"
                data-bs-toggle="pill"
                data-bs-target="#reconcilation"
                type="button"
                role="tab"
                aria-controls="reconcilation"
                aria-selected="false"
              >
                Settlement and Reconciliation
              </button>

              <button
                class="nav-link"
                id="security-tab"
                data-bs-toggle="pill"
                data-bs-target="#security"
                type="button"
                role="tab"
                aria-controls="security"
                aria-selected="false"
              >
                Security Metrics
              </button>

              <button
                class="nav-link"
                id="payment-tab"
                data-bs-toggle="pill"
                data-bs-target="#payment"
                type="button"
                role="tab"
                aria-controls="payment"
                aria-selected="false"
              >
                Payment Gateway Status
              </button>

              <button
                class="nav-link"
                id="technology-tab"
                data-bs-toggle="pill"
                data-bs-target="#technology"
                type="button"
                role="tab"
                aria-controls="technology"
                aria-selected="false"
              >
                Technology Performance
              </button>

              <button
                class="nav-link"
                id="backup-tab"
                data-bs-toggle="pill"
                data-bs-target="#backup"
                type="button"
                role="tab"
                aria-controls="backup"
                aria-selected="false"
              >
                Backup and Disaster Recovery
              </button>

              <button
                class="nav-link"
                id="storage-tab"
                data-bs-toggle="pill"
                data-bs-target="#storage"
                type="button"
                role="tab"
                aria-controls="storage"
                aria-selected="false"
              >
                Data storage
              </button>
            </div>
            <div class="tab-content custom-tab" id="v-pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <div className="content-inner">
                  <h4>Chargeback Rate</h4>
                  <ul>
                    <li>
                      <h6> Monitoring and managing chargeback issues</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
              >
                <div className="content-inner">
                  <h4>Settlement Time</h4>
                  <ul>
                    <li>
                      <h6>Ensuring timely settlement to merchants</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
              >
               <div className="content-inner">
                  <h4>Processing time</h4>
                  <ul>
                    <li>
                      <h6>Time taken to process each transaction</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                    <li>
                      <h6>Identifying and addressing delays</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
              >
                 <div className="content-inner">
                  <h4>Payment Gateway Uptime</h4>
                  <ul>
                    <li>
                      <h6>Monitoring the Availability and reliability of the payment gateway</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                    <li>
                      <h6>Downtime analysis and resolution</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="fraud"
                role="tabpanel"
                aria-labelledby="fraud-tab"
              >
                <div className="content-inner">
                  <h4>Fraud Detection</h4>
                  <ul>
                    <li>
                      <h6>Number of flagged transactions for potential fraud</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                    <li>
                      <h6>Effectiveness of fraud prevention measures</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="customer-support"
                role="tabpanel"
                aria-labelledby="customer-support-tab"
              >
                <div className="content-inner">
                  <h4>Customer Support Metrics</h4>
                  <ul>
                    <li>
                      <h6>Response time to customer queries and issues</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                    <li>
                      <h6>Customer satisfaction with support services / reviews and feedback</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="compliance"
                role="tabpanel"
                aria-labelledby="compliance-tab"
              >
                <div className="content-inner">
                  <h4>Compliance Metrics</h4>
                  <ul>
                    <li>
                      <h6>Adherence to industry standards (PCI DSS compliance)</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                    <li>
                      <h6>Compliance with legal and regulatory requirements</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="refund"
                role="tabpanel"
                aria-labelledby="refund-tab"
              >
                <div className="content-inner">
                  <h4>Refund Rate</h4>
                  <ul>
                    <li>
                      <h6>Monitoring and managing refund issues</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                   
                  </ul>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="reconcilation"
                role="tabpanel"
                aria-labelledby="reconcilation-tab"
              >
                <div className="content-inner">
                  <h4>Settlement and Reconciliation</h4>
                  <ul>
                    <li>
                      <h6>Bank Settlements, pending settlements, and reconciliation status to ensure accuracy</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                   
                  </ul>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="security"
                role="tabpanel"
                aria-labelledby="security-tab"
              >
                <div className="content-inner">
                  <h4>Security Metrics</h4>
                  <ul>
                    <li>
                      <h6>Monitoring and managing refund issues</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                    <li>
                      <h6>Ensuring the integrity of customer data</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="payment"
                role="tabpanel"
                aria-labelledby="payment-tab"
              >
                <div className="content-inner">
                  <h4>Payment Gateway Status</h4>
                  <ul>
                    <li>
                      <h6>Real-time information on the status and performance of the payment gateway, indicating any issues or downtimes</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                   
                  </ul>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="technology"
                role="tabpanel"
                aria-labelledby="technology-tab"
              >
                <div className="content-inner">
                  <h4>Technology Performance</h4>
                  <ul>
                    <li>
                      <h6>System performance and uptime</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>

                    <li>
                      <h6>Monitoring and addressing and technical issues. </h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                   
                  </ul>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="payment"
                role="tabpanel"
                aria-labelledby="payment-tab"
              >
                <div className="content-inner">
                  <h4>Technology Performance</h4>
                  <ul>
                    <li>
                      <h6>Implement and test backup procedures to ensure data integrity and availability</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>

                    <li>
                      <h6>Develop and maintain a disaster recovery plan to minimize downtime in case of emergencies  </h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                   
                  </ul>
                </div>

              </div>

              <div
                class="tab-pane fade"
                id="backup"
                role="tabpanel"
                aria-labelledby="backup-tab"
              >
                <div className="content-inner">
                  <h4>Backup and Disaster Recovery</h4>
                  <ul>
                    <li>
                      <h6>Implement and test backup procedures to ensure data integrity and availability</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>

                    <li>
                      <h6>Develop and maintain a disaster recovery plan to minimize downtime in case of emergencies  </h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>
                   
                  </ul>
                </div>

              </div>

              <div
                class="tab-pane fade"
                id="storage"
                role="tabpanel"
                aria-labelledby="storage-tab"
              >

                <div className="content-inner">
                  <h4>Data storage</h4>
                  <ul>
                    <li>
                      <h6>Ability to save and access historical data</h6>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries
                      </p>
                    </li>

                   
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
