// import React from "react";
// import ReactDOM from "react-dom";
// import Main from "./main";
// import * as serviceWorker from "./serviceWorker";
// import configureStore from "./redux/configureStore.prod";
// import { createBrowserHistory } from "history";
// import { Provider } from "react-redux";

// export const history = createBrowserHistory();
// const { persistor, store } = configureStore(history);
// ReactDOM.render(
//   <Provider store={store}>
//     <Main />
//   </Provider>,
//   document.getElementById("root")
// );
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import React from 'react';
import Main from './main';
import configureStore from './redux/configureStore.prod';
import { Provider } from 'react-redux';
import { createRoot } from "react-dom/client";

import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const { persistor, store } = configureStore(history);

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
         <Main/>
  </Provider>
);
