const transactions = [
    {
      id: 1,
      date: '2023-12-14',
      description: 'Grocery shopping',
      amount: 50.75,
      transfee:0.35,
      processfee:30,
      category: 'Food',
      account: 'Checking Account',
      type: 'Expense',
    },
    {
      id: 2,
      date: '2023-12-15',
      processfee:100,
      description: 'Salary Deposit',
      amount: 3000.00,
      transfee:100,
      category: 'Income',
      account: 'Salary Account',
      type: 'Income',
    },
    {
      id: 3,
      date: '2023-12-16',
      processfee:0.5,
      description: 'Online purchase',
      amount: -25.50,
      category: 'Shopping',
      account: 'Credit Card',
      type: 'Expense',
    },
    {
      id: 4,
      date: '2023-12-17',
      processfee:4,
      description: 'Dinner at a restaurant',
      amount: 400.00,
      transfee:0.5,
      category: 'Food',
      account: 'Credit Card',
      type: 'Expense',
    },
    {
      id: 5,
      date: '2023-12-18',
      description: 'Gas station refill',
      amount: 30.25,
      processfee:10,
      transfee:9,
      category: 'Transportation',
      account: 'Checking Account',
      type: 'Expense',
    },
    {
      id: 6,
      date: '2023-12-19',
      description: 'Freelance web development',
      amount: 800.00,
      processfee:100,
      category: 'Income',
      transfee:29,
      account: 'PayPal',
      type: 'Income',
    },
    {
      id: 7,
      date: '2023-12-20',
      description: 'Movie tickets',
      amount: 105.00,
      processfee:15,
      transfee:9,
      category: 'Entertainment',
      account: 'Credit Card',
      type: 'Expense',
    },
    {
      id: 8,
      date: '2023-12-21',
      description: 'Electricity bill',
      amount: 60.50,
      transfee:1.9,
      processfee:3.50,
      category: 'Utilities',
      account: 'Checking Account',
      type: 'Expense',
    },
    {
      id: 9,
      date: '2023-12-22',
      description: 'Amazon purchase',
      amount: 55.99,
      processfee:3.90,
      transfee:9.9,
      category: 'Shopping',
      account: 'Credit Card',
      type: 'Expense',
    },
    {
      id: 10,
      date: '2023-12-23',
      description: 'Book sale income',
      amount: 20.00,
      processfee:0.9,
      transfee:0.9,
      category: 'Income',
      account: 'PayPal',
      type: 'Income',
    },
  ];
  
  export default transactions;
  