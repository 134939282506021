import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import "./style.scss"; 
import { useSelector } from "react-redux"; 
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

const Adjustments = () => {
  const userid = localStorage?.getItem("userid");
  const mode = useSelector((state) => state.user);
  const modeType = mode?.mode ? "live" : "sandbox"; 
  const[adjustments,setAdjustments] = useState([]) 
  const getFundings = () => {
    loader(true);
    ApiClient.get(`fundings?merchant_id=${userid}&mode=${modeType}`).then(
      (res) => {
        loader(false);
        setAdjustments(res?.data?.adjustments || [])
      }
    );
  };

  useEffect(() => {
    getFundings();
  }, []);

  return (
    <>
      <Layout>
        
        <section className="">
          <div className="container">
            <div className="row">
              <div className="col-12 ">
                <div className="d-flex justify-content-between align-items-center ">
                  <h3 className="m-0 hedding">Adjustments</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="table-responsive table_section">
                  <table className="table">
                    <thead className="table_head">
                      <tr className="heading_row">
                        <th className="table_data">Amount</th>
                        <th className="table_data">Date Changed</th>
                        <th className="table_data">Funding Adjustment Id</th>
                        <th className="table_data">Currency </th>
                        <th className="table_data">Category</th>
                        <th className="table_data">Type</th>
                        <th className="table_data">Discription</th> 
                      </tr>
                    </thead>
                    <tbody>
                      {
                      adjustments?.length > 0 ? (
                        adjustments?.map((data, index) => (
                          <tr key={index}>
                            <td>{data.amount}</td>
                            <td>{data?.datechanged}</td>
                            <td>
                              <p className="line-break">{data.fundingadjustmentid}</p>
                            </td>
                            <td>
                              <p className="line-break">{data.currency}</p>
                            </td>
                            <td>
                              <p className="line-break">{data.category}</p>
                            </td>
                            <td>
                              <p className="line-break">
                                {data.type}
                              </p>
                            </td>
                            <td>
                              <p className="line-break">{data.discription}</p>
                            </td> 
                          </tr>
                        ))
                      ) : (
                        <tr className="credential_data">
                          <td colspan="4">No Funds</td>
                        </tr>
                      )}
                      <tr></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

      
      </Layout>
    </>
  );
};

export default Adjustments;
