import React, { useEffect, useState } from "react";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import Layout from "../../components/global/layout";
import { toast } from "react-toastify";

export default function FeeSettings() {
  const [formData, setFormData] = useState({
    id: "",
    logo: "",
    fav_icon: "",
    website_email: "",
    company_name: "",
    company_email: "",
    company_address: "",
    company_country_code: "",
    company_dial_code: "",
    company_mobile_no: "",
    support_email: "",
    google_analytics_script: "",
    merchant_fee: "",
    footer_info: "",
  });

  const GetSettingDetails = () => {
    loader(true);
    ApiClient.get(`setting`).then((res) => {
      if (res.success) {
        const newdata = res.data;
        setFormData({ id: newdata.id, merchant_fee: newdata.merchant_fee });
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetSettingDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loader(true);
    ApiClient.post(`setting`, formData).then((res) => {
      if (res.success) {
        toast.success("Settings updated successfully");
        GetSettingDetails();
      }
      loader(false);
    });
  };
  return (
    <Layout>
      <div>
        {/* <form onSubmit={handleSubmit}>
            <div className='col-md-12'>
            <div className='col-md-6 mb-3'>
          <label className='form-label'>Merchant Fee(%)</label>
          <input type="number"  value={formData.merchant_fee} className='form-control' name="merchant_fee" onChange={handleChange} />
        </div>
        </div>
        <div className='mr-4' style={{float:"right"}}>
<button className='btn btn-primary' type='submit'>Save</button>
        </div>
        </form> */}

    

        <ul class="nav nav-tabs settings-inner" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
             Chargeback Rate
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              Settlement Time
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#contact"
              type="button"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
            >
              Processing time
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3">
              <div className="graph_section">
                <div className="graph_range">
                  <div>
                    <h3 className="Amount_sales">Settlement Time</h3>
                  </div>
                </div>

                <div className="revenue_graph">
                  <img src="assets/img/chart-5.png" className="graphs" />
                </div>

                <div className="costst">
                  <p className="costes_para">No. of days</p>
                  <p className="costes_paras">Settled Transaction</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3">
              <div className="graph_section">
                <div className="graph_range">
                  <div>
                    <h3 className="Amount_sales">Settlement Time</h3>
                  </div>
                </div>

                <div className="revenue_graph">
                  <img src="/assets/img/chart-5.png" className="graphs" />
                </div>

                <div className="costst">
                  <p className="costes_para">No. of days</p>
                  <p className="costes_paras">Settled Transaction</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            ...
          </div>
        </div>
      </div>
    </Layout>
  );
}
