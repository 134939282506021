

let host = document.location.host;
let apiUrl ='https://luxapi.jcsoftwaresolution.in/api/'
// if(host.includes('localhost')){
//   apiUrl='http://portal.jcsoftwaresolution.in:6052/api/'
// }else if(host.includes('https')){
//   apiUrl='https://luxapi.jcsoftwaresolution.in/'
// }
const environment = {
    api: apiUrl,
    imageapi:"https://luxapi.jcsoftwaresolution.in/",
    map_api_key:'AIzaSyCbRhC6h9Pp43-5t_Knyrd_ewAdLMIJtCg',
    planTypeId:'64ad2ebce5aa778d26a54f02',
    userRoleId:'64b15102b14de6c28838f7d2',
    adminRoleId:'6540cfa35a7513892be61413',
    resellerTypeId:'64b23b7d690d1d5f7ae76102',
    productTypeId:'64a7d198fa039f179c0320ca',
    secretKey : 'jCu31UkI100ooYo230sc',
  };
  export default environment;
