import React from "react";

const Html = ({ ListItemLink, tabclass, urlAllow, route }) => {
  return (
    // <ul className="nav flex-column" component="siderbar">
    <ul className="nav flex-column" component="siderbar">
      {urlAllow("readDashboard") ? (
        <ListItemLink to="/dashboard">
          <i class="material-icons  mr-2" title="Dashboard">
            dashboard
          </i>
          <span class="side_head">Dashboard</span>
        </ListItemLink>
      ) : null}

      <li className="nav-item">
        {urlAllow("readRole") || urlAllow("readAdmins") ? (
          <>
            <a
              class={`col_side ${tabclass("user") ? "" : "collapsed"}`}
              data-bs-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div className="sidees">
                <i class="fa fa-user mr-2" title="User Management">
             
                </i>
                <span>User Management</span>
              </div>
              <div>
                <span className="side_icon">
                  <i class="material-icons">keyboard_arrow_right</i>
                </span>
              </div>
            </a>
          </>
        ) : (
          <></>
        )}

        <div
          class={`collapse ${tabclass("user") ? "show" : ""}`}
          id="collapseExample"
        >
          <div class="card card-body sides">
            {urlAllow("readRole") ? (
              <>
                <ListItemLink to="/roles">
                  {/* <i class="material-icons sidenv mr-2" title="Roles">supervised_user_circle</i> */}
                  <i class="fa fa-tasks sideicon mr-2" aria-hidden="true"></i>{" "}
                  <span>Roles</span>
                </ListItemLink>
              </>
            ) : (
              <></>
            )}
            {urlAllow("readAdmins") ? (
              <>
                <ListItemLink to="/users">
                  <i class="fa fa-users sideicon mr-2" aria-hidden="true"></i>{" "}
                  <span>Users</span>
                </ListItemLink>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </li>
      {urlAllow("readCategory") ? (
        <ListItemLink to="/categorylist">
          <i class="fa fa-clone  mr-2" aria-hidden="true"></i>
          <span> Category</span>
        </ListItemLink>
      ) : null} 

      <ListItemLink to="/account">
        <i class="fa fa-list-alt mr-2" aria-hidden="true"></i>
        <span> Accounts</span>
      </ListItemLink>


        {/* <ListItemLink to="/funds">
          <i class="fa fa-database" aria-hidden="true"></i>
          <i class="fa fa-usd"></i>
          <span class="side_head">Funds</span>
        </ListItemLink>
   */}

      {/* <ListItemLink to="/transaction/value">
        
        <i class="fa fa-random mr-2" aria-hidden="true"></i>
        <span> Transactions</span>
      </ListItemLink>
      <ListItemLink to="/AddCredential">
        
      <i class="fa fa-id-card mr-2" aria-hidden="true"></i>
        <span> Add Credentials</span>
      </ListItemLink> */}

      {/* {urlAllow("GenerateCredential") ? (
        <ListItemLink to="/balance">
      <i class="fa fa-solid fa-wallet mr-2"></i>

          <span class="side_head">d</span>
        </ListItemLink>
      ) : null} */}

      {urlAllow("readContent") ? (
        <>
          <ListItemLink to="/content">
            <i class="fa fa-cogs mr-2" title="Coupons">
            </i>
            <span>Content Management</span>
          </ListItemLink>
        </>
      ) : (
        <></>
      )}
{/*  
      {urlAllow("merchantContent") ? ( 
           
        <> 
         
          <ListItemLink to="/MerchantCustomer">
            <i class="material-icons mr-2" title="Coupons">
              wallet_membership
            </i>
            <span>Merchant's Customers</span>
          </ListItemLink>
        </>
      ) : (
        <></>
      )} */}

      <ListItemLink to="/faq">
        <i class="fa fa-question-circle mr-2" aria-hidden="true"></i>
        <span>FAQs</span>
      </ListItemLink>


      <ListItemLink to="/logs">
        <i class="fa fa-list mr-2" aria-hidden="true"></i>
        <span>Logs</span>
      </ListItemLink>
  


      <li className="nav-item">
        <>
          <a
            class={`col_side ${tabclass("settings") ? "" : "collapsed"}`}
            data-bs-toggle="collapse"
            href="#collapseExample1"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample1"
          >
            <div className="sidees">
              <i class="fa fa-wrench mr-2" aria-hidden="true"></i>
              <span>Settings</span>
            </div>
            <div>
              <span className="side_icon">
                <i class="material-icons">keyboard_arrow_right</i>
              </span>
            </div>
          </a>
        </>

        <div
          class={`collapse ${tabclass("settings") ? "show" : ""}`}
          id="collapseExample1"
        >
          <div class="card card-body sides">
            <ListItemLink to="/website/settings">
              <i class="fa fa-solid fa-screwdriver mr-2"></i>
              <span>Website Settings</span>
            </ListItemLink>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default Html;
