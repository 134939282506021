import React, { useState, useEffect } from "react";
import { ToastsStore } from "react-toasts";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import { useSelector } from "react-redux";
import methodModel from "../../../methods/methods";
import { userType } from "../../../models/type.model";
import Html from "./Html";
import { useNavigate, useParams } from "react-router-dom";
import environment from "../../../environment";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { useDispatch } from "react-redux";
const AddEditUser = () => {
  const { role, id } = useParams();

  const [images, setImages] = useState({ image: "", logo: "" });
  const defaultvalue = userType;
  const [Customer, setCustomerData] = useState([]);
  const [total, settotal] = useState();
  const [loaging, setLoader] = useState(true);
  const [form, setform] = useState({
    role: "",
    status: "active",
    dialCode: "+1",
    mobileNo: "",
    firstName: "",
    lastName: "",
    companyName: "",
    transaction_rate: "",
    

  });
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
  const [submitted, setSubmitted] = useState(false);
  const history = useNavigate();
  const [roles, setRoles] = useState([]);
  const [emailLoader, setEmailLoader] = useState(false);
  const [emailErr, setEmailErr] = useState("");
  const [ChangeStatus, setChangeStatus] = useState("");
  const [detail, setDetail] = useState();
  const user = useSelector((state) => state.user);
  const [customerr, setcustomer] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const searchState = useSelector((state) => state.search);
  const dispatch = useDispatch();

  const websitedetails = useSelector((state) => state.WebsiteDetails);
  const formValidation = [
    { key: "mobileNo", minLength: 10 },
    { key: "role", required: true },
    { key: "ic_number", minLength: 6 },
    { key: "password", minLength: 8 },
    {
      key: "confirmPassword",
      minLength: 8,
      confirmMatch: ["confirmPassword", "password"],
    },
  ];

  const [filter1, setFilter] = useState({
    merchant_id: id,
    page: 1,
    count: 10,
    search: "",
    isDeleted: false,
  });
  useEffect(() => {
    getCustomerData();
  }, []);

  useEffect(() => {
    if (user && user.loggedIn) {
      setFilter({ ...filter1, search: searchState.data });
      getCustomerData({ search: searchState.data, page: 1 });
    }
  }, [searchState]);

  useEffect(() => {
    setform({
      ...form,
      transaction_rate: websitedetails?.merchants_transaction_rate,
    });
  }, []);

  const getError = (key) => {
    // return methodModel.getError(key, form, formValidation)
  };

  const searchChange = (e) => {
    setSearch(e);
    if (!e) {
      //   dispatch(search_success(''))
    }
  };

  const clear = () => {
    setSearch("");
    dispatch(search_success(""));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (form.mobileNo == "") {
      toast.error("Mobile Number is Required");
      return false;
    }

    if (form.role == "") return false;
    let method = "post";
    let url = "add/user";
    let value = {
      ...form,
      ...images,
    };
    value.fullName = value.firstName + " " + value.lastName;
    if (value.id) {
      method = "put";
      url = "user/profile";
    } else {
      delete value.id;
    }

    if (form.dialCode != "") {
      loader(true);
      ApiClient.allApi(url, value, method).then((res) => {
        if (res.success) {
          toast.success(res.message);
          let url = "/users";
          if (role) url = "/users/" + role;
          history(url);
        }
        loader(false);
      });
    }
  };

  const imageResult = (e, key) => {
    images[key] = e.value;
    setImages(images);
  };

  const addressResult = (e) => {
    setform((state) => {
      return { ...state, address: e.value };
    });
  };

  const back = () => {
    history(-1);
  };

  const getRoles = () => {
    ApiClient.get("roles/listing", { status: "active" }).then((res) => {
      if (res.success) {
        let newarray = [];
        res.data &&
          res.data.map((item, index) => {
            if (item.id != environment.adminRoleId && item?.name != "Guest") {
              newarray.push(item);
            }
          });
        setRoles(newarray);
      }
    });
  };

  const emailCheck = (email) => {
    let isValid = methodModel.emailvalidation(email);
    if (isValid) {
      setEmailLoader(true);
      ApiClient.get("check/Email", { email: email }).then((res) => {
        if (!res.success) {
          if (detail?.email != email) {
            setEmailErr(res.error.message);
          }
        } else {
          setEmailErr("");
        }
        setEmailLoader(false);
      });
    }
  };

  const getCustomerData = (p = {}) => {
    setLoader(true);

    let filter = { ...filter1, ...p, merchant_id: id };
    let url = "merchant/transaction/customers";
    ApiClient.get(url, filter).then((res) => {
      if (res.success) {
        const data = res.data;
        setCustomerData(data);
        settotal(res?.total);
      }
      setLoader(false);
    });
  };

  const pageChange = (e) => {
    setFilter({ ...filter1, page: e });
    getCustomerData({ page: e });
  };

  useEffect(() => {
    setSubmitted(false); 
    if (id) {
      loader(true);
      ApiClient.get("user/profile", { id }).then((res) => {
        if (res.success) {
          let value = res.data;
          setDetail(value);
          let payload = defaultvalue;
          let oarr = Object.keys(defaultvalue);
          oarr.map((itm) => {
            payload[itm] = value[itm] || "";
          });
          payload.dialCode = value.dialCode;
          payload.role = value.role._id;
          payload.merchant_fee = value.merchant_fee;
          payload.status = value.status;
          payload.firstName = value.firstName;
          payload.lastName = value.lastName;
          payload.companyName = value.companyName;
          payload.transaction_rate = value.transaction_rate;
          payload.site = value?.site;
          setform({ ...payload });
          images.image = payload?.image;
          images.logo = payload?.logo;
          setImages(images);
        }
        loader(false);
      });
    }
    getRoles();
  }, [id]);

  return (
    <>
      <Html
        form={form}
        detail={detail}
        emailCheck={emailCheck}
        emailLoader={emailLoader}
        emailErr={emailErr}
        ChangeStatu={ChangeStatus}
        back={back}
        setEyes={setEyes}
        eyes={eyes}
        role={role}
        setform={setform}
        roles={roles}
        submitted={submitted}
        images={images}
        addressResult={addressResult}
        handleSubmit={handleSubmit}
        imageResult={imageResult}
        getError={getError}
        customerr={customerr}
        loaging={loaging}
        Customer={Customer}
        setcustomer={setcustomer}
        getCustomerData={getCustomerData}
        total={total}
        pageChange={pageChange}
        filter1={filter1}
        searchChange={searchChange}
        clear={clear}
        search={search}
      />
    </>
  );
};

export default AddEditUser;
