import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
 
 

const WebsiteDetail = () => {
    return async (dispatch) => {
      try {
        const result = await ApiClient.get(`setting`);
        if (result.success) {
          const iconlink = document.getElementById("geticon");
          iconlink.href = `${environment.imageapi}img/${result?.data?.fav_icon}`;
          return dispatch({ type: "GET_WEBSITE_DETAIL", data: result?.data });
        } else {
          return dispatch({ type: "POST_WEBSITE_DETAIL",data:{logo:"/assets/img/Logo_new.png",fa_icon:"/assets/img/Logo_new.png"} });
        }
      } catch (error) {
        console.error("Error fetching website detail:", error);
        return dispatch({ type: "WEBSITE_DETAIL_ERROR", error });
      }
    };
  };
  
export {WebsiteDetail};