import React from "react";
import Layout from "../../../components/global/layout";
import "react-phone-input-2/lib/style.css";


const Html = ({ id, form, handleSubmit, setform, history, back,decrypt }) => {
  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1">
            <div className="text-right title-head">
              <div className="d-flex justify-content-start ">
                <h3 className="hedding">
                  {" "}
                  <a onClick={(e) => back()} className="cursor-pointer">
                    <i
                      className="fa fa-arrow-left me-2 "
                      title="Back"
                      aria-hidden="true"
                    ></i>
                  </a>{" "}
                  {id ? "Edit" : "Add"} Credential
                </h3>
              </div>
            </div>
            <div className="product-detail-page">
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label>
                    Mode<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    value={form.mode}
                    onChange={(e) => setform({ ...form, mode: e.target.value })}
                    required
                  >
                    <option value="" disabled>
                      Select mode
                    </option>
                    <option value="sandbox">Sandbox</option>
                    <option value="live">Live</option>
                  </select>
                </div>

                <div className="col-md-6 mb-3">
                  <label>
                    Site<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={form.site}
                    onChange={(e) => setform({ ...form, site: e.target.value })}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label>
                    Marchant Id<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={form.merchid}
                    onChange={(e) =>
                      setform({ ...form, merchid: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label>
                    Api User Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={form.authuname}
                    onChange={(e) =>
                      setform({ ...form, authuname: e.target.value })
                    }
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                {!id ? (
                  <div className="col-md-6 mb-3">
                    <label>
                      Api Password<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={form.authpass}
                      onChange={(e) =>
                        setform({ ...form, authpass: e.target.value })
                      }
                      required
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-6 mb-3">
                  <label>
                    Is Ach<span className="text-danger">*</span>
                  </label>
                  <div className="form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="isAchToggle"
                      checked={form.isAch}
                      onChange={(e) =>
                        setform({ ...form, isAch: e.target.checked })
                      }
                      
                    />
                  </div>
                </div>
              </div>

              <div className="text-right">
                <button
                  onClick={() => history("/addcredential")}
                  className="btn btn-primary mr-2"
                >
                  Back
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default Html;
