import React, { useState, useEffect } from "react";
import { ToastsStore } from "react-toasts";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import { roleType } from "../../models/type.model";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import statusModel from "../../models/status.model";
import SelectDropdown from "../../components/common/SelectDropdown";
import { toast } from "react-toastify";
import environment from "../../environment";

const AddEditRole = () => {
  const defaultvalue = () => {
    let keys = { ...roleType };
    Object.keys(roleType).map((itm) => {
      if (itm != "permissions") keys[itm] = "";
    });
    Object.keys(roleType.permissions[0]).map((itm) => {
      keys.permissions[itm] = false;
    });
    keys.status = "active";
    return keys;
  };
  const { id } = useParams();
  const [form, setform] = useState(roleType);
  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);
  const formValidation = [{ key: "status", required: true }];

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (form.loginPannel == "") return;
    if (invalid) return;
    let method = "post";
    let url = "role";
    let value = {
      ...form,
    };
    if (value.id) {
      method = "put";
      url = "role/update";
    } else {
      delete value.id;
    }

    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        toast.success(res.message);
        history("/roles");
      }
      loader(false);
    });
  };

  const setpermission = (key, value) => {
    setform({
      ...form,
      permissions: [
       { ...form.permissions[0],
       [key]: value},
      ],
    });
  };

  useEffect(() => {
    if (id) {
      setform({});
      loader(true);
      ApiClient.get("role/detail", { id }).then((res) => {
        if (res.success) {
          let value = res.data;
          let payload = {
            id: "",
            name: "",
            status: "active",
            permissions: [{
              //  Done
              readDashboard: false,
              readCustomer: false,
              readAssessment: false,
              editAssessment: false,
              deleteAssessment: false,
              addAssessment: false,
              addQuestion: false,
              editQuestion: false,
              readQuestion: false,
              addCustomer: false,
              editCustomer: false,
              deleteCustomer: false,
              readRole: false,
              addRole: false,
              editRole: false,
              readSkillType: false,
              addSkillType: false,
              editSkillType: false,
              deleteSkillType: false,
              // Done
              readCategory: false,
              addCategory: false,
              editCategory: false,
              deleteCategory: false,
              readResellerCategory: false,
              addResellerCategory: false,
              editResellerCategory: false,
              deleteResellerCategory: false,
              readAdmins: false,
              addAdmins: false,
              editAdmins: false,
              deleteAdmins: false,
              readTypes: false,
              addTypes: false,
              editTypes: false,
              deleteTypes: false,
              readCategory: false,
              addCategory: false,
              editCategory: false,
              deleteCategory: false,
              readResellerCategory: false,
              addResellerCategory: false,
              editResellerCategory: false,
              deleteResellerCategory: false,
              readPlanFeatures: false,
              addPlanFeatures: false,
              editPlanFeatures: false,
              deletePlanFeatures: false,
              readPlan: false,
              addPlan: false,
              editPlan: false,
              deletePlan: false,
              readCoupons: false,
              addCoupons: false,
              editCoupons: false,
              deleteCoupons: false,
              readCurrency: false,
              addCurrency: false,
              editCurrency: false,
              deleteCurrency: false,
              readBooking: false,
              addBooking: false,
              editBooking: false,
              deleteBooking: false,
              refreshBooking: false,
              readContinents: false,
              addContinents: false,
              editContinents: false,
              deleteContinents: false,
              readCountries: false,
              readContent: false,
              editContent: false,
              addCountries: false,
              editCountries: false,
              deleteCountries: false,
              readRegion: false,
              addRegion: false,
              editRegion: false,
              deleteRegion: false,
              readCities: false,
              addCities: false,
              editCities: false,
              deleteCities: false,
              readEmailTemplate: false,
              addEmailTemplate: false,
              editEmailTemplate: false,
            }],
          };

          Object.keys(payload).map((itm) => {
            if(itm!='permissions'){
            payload[itm] = value[itm];
          }
        });
          payload.loginPortal = value.loginPortal;
          if (value.permissions) {
            payload.permissions = value.permissions;
          }
          setform({
            ...payload,
          });
        }
        loader(false);
      });
    } else {
      setform(defaultvalue());
    }
  }, [id]);
  //  For Handling Category Checkbboxs
  const handleallCategorycheckbox = (check) => {
    let value = true;
    if (check) {
      value = true;
    } else {
      value = false;
    }
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readSkillType: value,
        addSkillType: value,
        editSkillType: value,
        deleteSkillType: value,
      },
    });
  };
  //  For Handling Dashboard permission
  const hanldealldashboardpermission = (check) => {
    let value = true;
    if (check) {
      value = true;
    } else {
      value = false;
    }
    setform({
      ...form,
      permissions: { ...form.permissions, readDashboard: value },
    });
  };
  //  For Handling Skills Permission
  const HandleCutomerPer = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readSkills: value,
        addSkills: value,
        deleteSkills: value,
        editSkills: value,
      },
    });
  };
  //  For Handling Role Permission
  const handleRolesPer = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readRole: value,
        addRole: value,
        editRole: value,
      },
    });
  };
  const handleAdminUser = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readAdmins: value,
        addAdmins: value,
        deleteAdmins: value,
        editAdmins: value,
      },
    });
  };
  const handleTypesPer = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readTypes: value,
        addTypes: value,
        deleteTypes: value,
        editTypes: value,
      },
    });
  };
  const handleResellCateg = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readResellerCategory: value,
        addResellerCategory: value,
        deleteResellerCategory: value,
        editResellerCategory: value,
      },
    });
  };
  const handlePlanFeature = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readPlanFeatures: value,
        addPlanFeatures: value,
        deletePlanFeatures: value,
        editPlanFeatures: value,
      },
    });
  };
  const handlePlanPre = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readPlan: value,
        addPlan: value,
        deletePlan: value,
        editPlan: value,
      },
    });
  };
  const handleCoupons = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readCoupons: value,
        addCoupons: value,
        deleteCoupons: value,
        editCoupons: value,
      },
    });
  };
  const handleCurrencyPre = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readCurrency: value,
        addCurrency: value,
        deleteCurrency: value,
        editCurrency: value,
      },
    });
  };
  const handleBookingPre = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readBooking: value,
        addBooking: value,
        deleteBooking: value,
        editBooking: value,
        refreshBooking: value,
      },
    });
  };
  const HandleContinent = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readContinents: value,
        addContinents: value,
        deleteContinents: value,
        editContinents: value,
      },
    });
  };
  const handleCountriuesPre = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readCountries: value,
        addCountries: value,
        deleteCountries: value,
        editCountries: value,
      },
    });
  };
  const HandleRegionPre = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readRegion: value,
        addRegion: value,
        deleteRegion: value,
        editRegion: value,
      },
    });
  };
  const HandleCitiesPre = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readCities: value,
        addCities: value,
        deleteCities: value,
        editCities: value,
      },
    });
  };
  const handleEmailPre = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readEmailTemplate: value,
        addEmailTemplate: value,
        editEmailTemplate: value,
      },
    });
  };
  const HandleContentper = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readContent: value,
        editContent: value,
        deleteContent: value,
        addContent: value,
      },
    });
  };

  const HandleAll = (check) => {
    let value = check ? true : false;
    let permissions = {...roleType.permissions[0]};
    Object?.keys(permissions).map((itm) => {
      permissions[itm] = value;
    });
    setform({ ...form, permissions: [permissions] });
  };

  const isAllChecked = () => {
    let value = true;
    let permissions = form.permissions[0]
    Object.keys(permissions).map((itm) => {
      if (!permissions[itm]) value = false;
    }); 
    return value;
  };


 

  const HandleAllRead = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: [{
        ...form.permissions[0],
        readAdmins: value,
        readDashboard: value,
        readRole: value,
        readTypes: value,
        readContent: value,
      }],
    });
  };
  const HandleAllAdd = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: [{
        ...form.permissions[0],
        addAdmins: value,
        addRole: value,
        addTypes: value,
        addContent: value,
      }],
    });
  };
  const HandleallEdit = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: [{
        ...form.permissions[0],
        editAdmins: value,
        editRole: value,
        editTypes: value,
        editContent: value,
      }],
    });
  };
  const HandleAllDelete = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: [{
        ...form.permissions[0],
        deleteAdmins: value,
        deleteTypes: value,
        deleteContent: value,
      }],
    });
  };
  const HandleRefreshAll = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: { ...form.permissions, refreshBooking: value },
    });
  };
  useEffect(() => {
    setform({ ...form, permissions: roleType.permissions });
  }, [id]);

  const isAllow = (key = "") => {
    let permissions = user.role?.permissions;
    let value = permissions?.[key];
    if (user?.role?.id == environment.adminRoleId) value = true;
    return value;
  };

  const HandleAssessmentPer = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readAssessment: value,
        editAssessment: value,
        addAssessment: value,
        deleteAssessment: value,
      },
    });
  };
  const handleQuestion = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        readQuestion: value,
        editQuestion: value,
        addQuestion: value,
        deleteQuestion: value,
      },
    });
  };
  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1">
            <h3 className="ViewUser mb-3">
              {form && form.id ? "Edit" : "Add"} Role
            </h3>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div className=" mb-3 customer_name">
                <label>
                  Name<span className="star">*</span>
                </label>
                <input
                  type="text"
                  disabled
                  className="form-control"
                  value={form.name}
                  onChange={(e) => setform({ ...form, name: e.target.value })}
                  required
                />
              </div>

              <div className="edit-space d-flex align-items-center justify-content-between gap-3">
                <div className="mb-3">
                  <label>
                    Status<span className="star">*</span>
                  </label>
                  <SelectDropdown
                    id="statusDropdown"
                    displayValue="name"
                    placeholder="Select Status"
                    intialValue={form.status}
                    result={(e) => {
                      setform({ ...form, status: e.value });
                    }}
                    options={statusModel.list}
                  />
                  {submitted && !form.status ? (
                    <div className="text-danger">Status is Required</div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="mb-3 ">
                  <label>
                    Login From<span className="star">*</span>
                  </label>
                  <SelectDropdown
                    id="statusDropdown"
                    displayValue="name"
                    placeholder="Select Login from"
                    intialValue={form.loginPortal}
                    result={(e) => {
                      setform({ ...form, loginPortal: e.value });
                    }}
                    options={statusModel.loginfrom}
                  />
                  {submitted && !form.loginPortal ? (
                    <div className="text-danger">Login From is Required</div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <h5 className="mb-0 mt-4">Permissions</h5>
            </div>
            <div class="table-responsive">
              <div class="table_section tablepadding">
                <table class="table table-striped table-bordered table-hover">
                  <thead class="table_head roleTable">
                    <tr class="heading_row">
                      <th scope="col" class="table_data"></th>
                      <th scope="col" class="table_data">
                        <input
                          type="checkbox"
                          onChange={(e) => HandleAll(e.target.checked)}
                          checked={isAllChecked()}
                        />
                        All
                      </th>
                      <th scope="col" class="table_data">
                        <input
                          type="checkbox"
                          onChange={(e) => HandleAllRead(e.target.checked)}
                          checked={form.permissions[0]&&
                            form.permissions[0].readDashboard &&
                            form.permissions[0].readRole &&
                            form.permissions[0].readAdmins &&
                            form.permissions[0].readTypes &&
                            form.permissions[0].readContent
                          }
                        />
                        Read
                      </th>
                      <th scope="col" class="table_data">
                        {" "}
                        <input
                          type="checkbox"
                          onChange={(e) => HandleAllAdd(e.target.checked)}
                          checked={form.permissions[0]&&
                            form.permissions[0]?.addRole &&
                            form.permissions[0]?.addAdmins &&
                            form.permissions[0]?.addTypes &&
                            form.permissions[0]?.addContent
                          }
                        />
                        Add
                      </th>
                      <th scope="col" class="table_data">
                        {" "}
                        <input
                          type="checkbox"
                          onChange={(e) => HandleallEdit(e.target.checked)}
                          checked={form.permissions[0]&&
                            form.permissions[0]?.editRole &&
                            form.permissions[0]?.editAdmins &&
                            form.permissions[0]?.editTypes &&
                            form.permissions[0]?.editContent
                          }
                        />
                        Edit
                      </th>
                      <th scope="col" class="table_data">
                        {" "}
                        <input
                          type="checkbox"
                          onChange={(e) => HandleAllDelete(e.target.checked)}
                          checked={form.permissions[0]&&
                            form.permissions[0]?.deleteAdmins &&
                            form.permissions[0]?.deleteTypes &&
                            form.permissions[0]?.deleteContent
                          }
                        />
                        Delete
                      </th>
                      {/* <th scope="col" class="table_data"> <input
                            type="checkbox"  onChange={e=>HandleRefreshAll(e.target.checked)} checked={form.permissions[0]?.refreshBooking}/>Refresh</th> */}
                    </tr>
                  </thead>
                  <tbody className="roleTable">
                    <tr>
                      <td>
                        <b>Dashboard</b>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            hanldealldashboardpermission(e.target.checked)
                          }
                          checked={form.permissions[0]?.readDashboard}
                        />
                      </td>
                      <td>
                        <div className="checkList">
                          <label>
                            <input
                              type="checkbox"
                              checked={form.permissions[0]?.readDashboard}
                              onChange={(e) =>
                                setpermission("readDashboard", e.target.checked)
                              }
                            />{" "}
                          </label>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>
                        <b>Roles</b>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => handleRolesPer(e.target.checked)}
                          checked={
                            form.permissions[0]?.readRole &&
                            form.permissions[0]?.addRole &&
                            form.permissions[0]?.editRole
                          }
                        />
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={form.permissions[0]?.readRole}
                            onChange={(e) =>
                              setpermission("readRole", e.target.checked)
                            }
                          />{" "}
                        </label>
                      </td>
                      <td>
                        <label>
                          {/* <input
                                type="checkbox"
                                checked={form.permissions[0]?.addRole}
                                onChange={(e) =>
                                  setpermission('addRole', e.target.checked)
                                }
                              />{' '} */}
                        </label>
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={form.permissions[0]?.editRole}
                            onChange={(e) =>
                              setpermission("editRole", e.target.checked)
                            }
                          />{" "}
                        </label>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>
                        <b>Users</b>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => handleAdminUser(e.target.checked)}
                          checked={
                            form.permissions[0]?.readAdmins &&
                            form.permissions[0]?.addAdmins &&
                            form.permissions[0]?.deleteAdmins &&
                            form.permissions[0]?.editAdmins
                          }
                        />
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={form.permissions[0]?.readAdmins}
                            onChange={(e) =>
                              setpermission("readAdmins", e.target.checked)
                            }
                          />
                        </label>
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={form.permissions[0]?.addAdmins}
                            onChange={(e) =>
                              setpermission("addAdmins", e.target.checked)
                            }
                          />{" "}
                        </label>
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={form.permissions[0]?.editAdmins}
                            onChange={(e) =>
                              setpermission("editAdmins", e.target.checked)
                            }
                          />{" "}
                        </label>
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={form.permissions[0]?.deleteAdmins}
                            onChange={(e) =>
                              setpermission("deleteAdmins", e.target.checked)
                            }
                          />{" "}
                        </label>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <b>Category </b>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => handleTypesPer(e.target.checked)}
                          checked={
                            form.permissions[0]?.readTypes &&
                            form.permissions[0]?.addTypes &&
                            form.permissions[0]?.deleteTypes &&
                            form.permissions[0]?.editTypes
                          }
                        />
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={form.permissions[0]?.readTypes}
                            onChange={(e) =>
                              setpermission("readTypes", e.target.checked)
                            }
                          />{" "}
                        </label>
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={form.permissions[0]?.addTypes}
                            onChange={(e) =>
                              setpermission("addTypes", e.target.checked)
                            }
                          />{" "}
                        </label>
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={form.permissions[0]?.editTypes}
                            onChange={(e) =>
                              setpermission("editTypes", e.target.checked)
                            }
                          />{" "}
                        </label>
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={form.permissions[0]?.deleteTypes}
                            onChange={(e) =>
                              setpermission("deleteTypes", e.target.checked)
                            }
                          />{" "}
                        </label>
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>
                        <b>Content </b>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => HandleContentper(e.target.checked)}
                          checked={
                            form.permissions[0]?.readContent &&
                            form.permissions[0]?.editContent &&
                            form.permissions[0]?.addContent &&
                            form.permissions[0]?.deleteContent
                          }
                        />
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={form.permissions[0]?.readContent}
                            onChange={(e) =>
                              setpermission("readContent", e.target.checked)
                            }
                          />{" "}
                        </label>
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={form.permissions[0]?.addContent}
                            onChange={(e) =>
                              setpermission("addContent", e.target.checked)
                            }
                          />{" "}
                        </label>
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={form.permissions[0]?.editContent}
                            onChange={(e) =>
                              setpermission("editContent", e.target.checked)
                            }
                          />{" "}
                        </label>
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={form.permissions[0]?.deleteContent}
                            onChange={(e) =>
                              setpermission("deleteContent", e.target.checked)
                            }
                          />{" "}
                        </label>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="text-right mt-4">
              <Link to="/roles" className="btn btn-secondary discard mr-2">
                Back
              </Link>
              {!id || (id && isAllow("editRole")) ? (
                <button type="submit" className="btn btn-primary ">
                  Save
                </button>
              ) : null}
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddEditRole;
